import Link from 'next/link';

import Layout from '../components/Layout';
import T from '../components/Typography';
import U from '../components/UtilityStyle';

export default function Custom404() {
  return (
    <Layout>
      <div style={{ textAlign: 'center', marginTop: 56 }}>
        <T.H1>404 - Page not found</T.H1>
        <Link href="/d" legacyBehavior>
          <U.Link>Please click here to return to your dashboard</U.Link>
        </Link>
      </div>
    </Layout>
  );
}
